// 房源预约 tableColumn
export const tableColumn = [
    {
        type: "index",
        label: "#",
        width: 50
    },
    {
        prop: "name",
        label: "姓名",
        width: 150
    },
    {
        prop: "tel1",
        label: "电话",
        width: 150
    },
    {
        prop: "workInfo",
        label: "工作单位",
    },
    {
        prop: "idcard",
        label: "身份证",
    },
    {
        prop: "address",
        label: "所住位置",
    },
    {
        prop: "category",
        label: "缴费标准",
    },
    {
        prop: "omment",
        label: "备注",
    }
];

// 房源预约 tableColumn
export const billsTableColumn = [
    {
        type: "index",
        label: "#",
        width: 50
    },
    {
        prop: "name",
        label: "账单名称",
    },

    {
        prop: "amount",
        label: "金额",
        width: 100,
        render: (h, {row}) => {
            let {amount} = row;
            return h("span", (amount/100).toFixed(2));
        }
    },
    {
        prop: "comment",
        label: "备注",
    }
];

