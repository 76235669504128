<template>
    <section class="rental-management padding_10_15">
        <div class="form-search-container bg-white">
            <el-form :model="formSearch" inline size="small">
                <el-form-item>
                    <el-input v-model="formSearch.keyword" placeholder="请输入租户名称" clearable/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleSearch">查询</el-button>
                    <el-button @click="handleReset">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <r-e-table class="bg-white" ref="rentalTableRef" :dataRequest="getRentalTableData" :columns="tableColumn"
                   :height="810">
            <template slot="empty">
                <el-empty/>
            </template>
            <el-table-column slot="toolbar" label="操作" width="200">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <el-button size="mini" style="padding: 5px 10px !important;" @click="tuifangdan(row)">打印</el-button>
                        <el-button size="mini" style="padding: 5px 10px !important;" @click="kan(row)">查看</el-button>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>
        <drawer-tenant-info ref="drawerTenantInfo"/>
    </section>
</template>

<script>
    import {getRentalListApi,tuifangdan} from "@/api/rental-management";
    import {tableColumn} from "@/views/rental-management/data";
    export default {
        name: "rental-management",
        data() {
            return {
                tableColumn,
                formSearch: {
                    keyword: ""
                }
            };
        },
        methods: {
            getRentalTableData(params) {
                // 获取租户列表
                return getRentalListApi({...params, ...this.formSearch});
            },
            handleSearch() {
                // 搜索
                this.$refs["rentalTableRef"].getTableData();
            },
            handleReset() {
                // 重置
                this.formSearch.keyword = "";
                this.handleSearch();
            },
            async tuifangdan(data){
                const loading = this.$loading({lock: true, text: "请您稍等...", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)"});
                let {uuid}=data;
                let resd= await tuifangdan(uuid);
                if(resd){
                    const binaryData = [];
                    binaryData.push(resd);
                    let pdfUrl = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/pdf' }));
                    let tempwindow = window.open("",'_blank');
                    tempwindow.location =pdfUrl;
                }
                loading.close();
            },

            kan(data){
                this.$refs["drawerTenantInfo"].openDrawer(data);
            },
        },
        components: {
            drawerTenantInfo: () => import("./components/drawer-tenant-info.vue")
        }
    };
</script>

<style lang="scss" scoped>
    .rental-management {

        .form-search-container {
            padding: VH(20px) VW(15px) 0;
            margin-bottom: VH(15px);

            /deep/ .el-form {
                .el-form-item .el-input__inner {
                    width: VW(250px);
                }
            }
        }
    }
</style>
