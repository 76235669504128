import request from "@http";

// 获取租户列表
export function getRentalListApi(params) {
    return request({
        method: "get",
        url: `/api/gzf/baseinfo/leasor/`,
        params,
    });
}

export function tuifangdan(uuid) {
    return request({
        method: "get",
        url: `/api/gzf/baseinfo/leasor/${uuid}/tuifangdan`,
        responseType: 'blob',
    });
}

// 充值
export function addDeposit(uuid,data) {
    return request({
        method: "put",
        url: `/api/gzf/baseinfo/leasor/${uuid}/deposit/`,
        data,
    });
}

// 查询余额
export function getRent(uuid) {
    return request({
        method: "get",
        url: `/api/gzf/baseinfo/leasor/${uuid}/deposit`,
    });
}

// 查询未缴房租账单
export function getLeasorBills(uuid, params) {
    return request({
        method: "get",
        url: `/api/gzf/baseinfo/leasor/${uuid}/bills/rental/unpaid`,
        params,
    });
}